<template>
  <v-container>
    <v-card
        v-show="!submitLoading"
        :width="$vuetify.breakpoint.mobile ? '90vw' : '70vw'"
        class="mx-auto pa-6 mt-2"
        color="secondary"
        flat
    >
      <v-card-title
      ><h2
          :class="$vuetify.breakpoint.smAndDown ? 'ml-n2' : '0'"
          class="white--text text-break"
      >
        Edit a part: <span class="primary--text">{{ productName }}</span>
      </h2></v-card-title
      >
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-1' : 'pa-3'">
        <v-form ref="form" class="grey lighten-3 rounded-lg pa-4">
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="3">
              <v-subheader>* Product Name</v-subheader>
            </v-col>
            <v-col cols="12" lg="9" md="9">
              <v-text-field
                  v-model.trim="productName"
                  :error-messages="productNameErrors"
                  class="rounded-pill"
                  flat
                  required
                  solo
                  @blur="$v.productName.$touch()"
                  @input="$v.productName.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Price</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-text-field
                  v-model.trim="price"
                  :error-messages="priceErrors"
                  class="rounded-pill"
                  flat
                  placeholder="$"
                  required
                  solo
                  type="number"
                  @blur="$v.price.$touch()"
                  @input="$v.price.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Vehicle Manufacturer</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-combobox
                  v-model.trim="brand"
                  :class="$vuetify.breakpoint.mobile ? 'rounded-xl' : 'rounded-pill'"
                  :error-messages="brandErrors"
                  :items="brandItems"
                  chips
                  clearable
                  flat
                  hint="You can select multiple brands or type your own and press Enter"
                  multiple
                  placeholder="Who created the car? (Max 6)"
                  required
                  solo
                  @blur="$v.brand.$touch()"
                  @change="arrayLengthCheck(brand, 6)"
                  @input="$v.brand.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Vehicle Model</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-combobox
                  v-model.trim="model"
                  :class="$vuetify.breakpoint.mobile ? 'rounded-xl' : 'rounded-pill'"
                  :error-messages="modelErrors"
                  :items="modelItems"
                  chips
                  clearable
                  flat
                  hint="You can select multiple models or type your own and press Enter"
                  multiple
                  placeholder="What model of car is it for? (Max 6)"
                  required
                  solo
                  @blur="$v.model.$touch()"
                  @change="arrayLengthCheck(model, 6)"
                  @input="$v.model.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Brand</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-combobox
                  v-model.trim="manufacturer"
                  :error-messages="manufacturerErrors"
                  :items="manufacturerItems"
                  class="rounded-pill"
                  clearable
                  flat
                  placeholder="Who made the part?"
                  required
                  solo
                  @blur="$v.manufacturer.$touch()"
                  @input="$v.manufacturer.$touch()"
              />
            </v-col>
          </v-row>

          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Condition</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-select
                  v-model.trim="condition"
                  :error-messages="conditionErrors"
                  :items="conditions"
                  class="rounded-pill"
                  clearable
                  flat
                  placeholder="How used is the product?"
                  required
                  solo
                  @blur="$v.condition.$touch()"
                  @change="$v.condition.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>Car year</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-select
                  v-model.trim="year"
                  :error-messages="yearErrors"
                  :items="getYears"
                  class="rounded-pill"
                  clearable
                  flat
                  placeholder="Year"
                  required
                  solo
                  @blur="$v.year.$touch()"
                  @change="$v.year.$touch()"
              />
            </v-col>
          </v-row>
          <v-row
              :no-gutters="$vuetify.breakpoint.mobile"
              cols="12"
              lg="9"
              md="9"
          >
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>* Type</v-subheader>
            </v-col
            >
            <v-col>
              <v-select
                  v-model.trim="type"
                  :error-messages="typeErrors"
                  :items="types"
                  class="rounded-pill"
                  clearable
                  flat
                  placeholder="What style of car is it made for?"
                  required
                  solo
                  @blur="$v.type.$touch()"
                  @change="$v.type.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.mobile">
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>Location</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-text-field
                  v-model.trim="address"
                  class="rounded-pill"
                  flat
                  label="Address"
                  solo
              ></v-text-field>
              <v-row :no-gutters="$vuetify.breakpoint.mobile">
                <v-col cols="12" lg="4" md="4">
                  <v-text-field
                      v-model.trim="city"
                      class="rounded-pill"
                      flat
                      label="City"
                      solo
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4" md="4">
                  <v-text-field
                      v-model.trim="postcode"
                      :error-messages="postcodeErrors"
                      class="rounded-pill"
                      flat
                      label="Post Code"
                      solo
                      type="number"
                      @blur="$v.postcode.$touch()"
                      @input="$v.postcode.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4" md="4">
                  <v-select
                      v-model.trim="state"
                      :items="states"
                      class="rounded-pill"
                      flat
                      label="State"
                      solo
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>Description</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-textarea
                  v-model.trim="description"
                  :counter="490"
                  :error-messages="descriptionErrors"
                  class="rounded-xl"
                  flat
                  placeholder="Tell people all about your part! A useful description helps convince users."
                  solo
                  @blur="$v.description.$touch()"
                  @input="$v.description.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" md="3"
            >
              <v-subheader>Categories</v-subheader>
            </v-col
            >
            <v-col cols="12" lg="9" md="9">
              <v-combobox
                  v-model.trim="tags"
                  :class="$vuetify.breakpoint.mobile ? 'rounded-xl' : 'rounded-pill'"
                  :error-messages="tagsErrors"
                  :items="tagItems"
                  chips
                  clearable
                  flat
                  multiple
                  placeholder="You can select or type multiple categories to help people find your product."
                  solo
                  @blur="$v.tags.$touch()"
                  @change="arrayLengthCheck(tags, 5)"
                  @input="$v.tags.$touch()"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-3">
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"
          >
            <v-subheader
                :class="{ 'ml-4': !$vuetify.breakpoint.mobile }"
                class="white--text mt-n1"
            >* Images
            </v-subheader
            >
          </v-col
          >
          <v-col>
            <v-subheader
                v-if="$vuetify.breakpoint.mobile"
                class="white--text mt-n1"
            >Images
            </v-subheader
            >
            <FileUpload :givenImages="this.images" @updateImages="updateImgs($event)"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
                :disabled="submitLoading"
                block
                class="mb-12"
                color="primary white--text"
                @click="editPartCall"
            >Save Changes
            </v-btn>
            <v-btn
                :disabled="submitLoading"
                block
                color="accent darken-2 white--text"
                @click="$router.go(-1)"
            >Cancel Changes
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <div
        v-if="submitLoading"
        class="d-flex-column justify-center align-center text-center my-16"
    >
      <h5 class="text-h4 my-6">
        Submitting product update, when this process completes you will be moved to the
        product's page.
      </h5>
      <p>
        Note: Valid Submissions could still be processed if you leave this page.
        Double check your account for details.
      </p>
      <p>
        If you seem to be stuck on this page, try refreshing the page after
        about 3 minutes.
      </p>
    </div>
    <div
        v-if="submitLoading"
        class="d-flex justify-center align-center text-center my-16"
    >
      <v-progress-circular :size="170" color="primary" indeterminate/>
    </div>
  </v-container>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";
import Loader from "@/components/Loader.vue";
import {mapActions, mapState} from "vuex";
import {helpers, maxLength, minValue, required,} from "vuelidate/lib/validators";

const errorCodes = {
  "noPostData": ["error", "There was an issue sending data, please try again or login again"],
  "noFileData": ["error", "There was an issue sending data, please try again or login again"],
  "noUsersID": ["error", "We had trouble finding the user, make sure you are logged in, or login again"],
  "noUsersPwd": ["error", "We had trouble finding the user, make sure you are logged in, or login again"],
  "noPartsID": ["error", "We had trouble finding the part, refresh, come back later or login again"],
  "noProductName": ["warning", "Please type in a product name"],
  "noManufacturer": ["warning", "Please select a manufacturer"],
  "noBrand": ["warning", "Please select a brand"],
  "noModel": ["warning", "Please select a model"],
  "noCondition": ["warning", "Please select a condition"],
  "noPrice": ["warning", "Please enter a valid price"],
  "noFiles": ["warning", "Please upload at least one image"],
  "imageNot": ["error", "The file you uploaded is not an image"],
  "imageExists": ["error", "The file you uploaded already exists"],
  "imageSize": ["error", "The file you uploaded is too large"],
  "imageType": ["error", "The file you uploaded is not a valid image type (jpg, jpeg, png, gif)"],
  "userNoExist": ["error", "We had trouble finding the user, make sure you are logged in, or login again"],
  "incorrectPassword": ["error", "The submitted password was incorrect, please login again"]
}

export default {
  metaInfo: {
    title: "Editing Part",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  components: {
    FileUpload,
    Loader,
  },
  emits: ["updateImages"],
  data: () => ({
    productName: "",
    price: 0,
    brand: "",
    model: "",
    manufacturer: "",
    condition: "",
    year: 0,
    type: "",
    address: "",
    city: "",
    postcode: "",
    state: "NSW",
    description: "",
    tags: [],
    usersID: "",
    usersPwd: "",
    images: [],
    submitLoading: false,
    addImages: false,
    // Items
    brandItems: [],
    modelItems: [],
    manufacturerItems: [],
    conditions: ["New", "Used", "Poor"],
    types: ["All", "N/A", "Sedan", "Hatchback", "Ute"],
    states: ["NSW", "VIC", "ACT", "WA", "NT", "SA", "QLD", "TAS"],
    tagItems: [],
  }),
  computed: {
    ...mapState("user", ["currentUser"]),
    ...mapState(["baseURL"]),
    getYears() {
      let _years = [];
      _years.push("Any");
      const date = new Date().getFullYear();
      for (let year = date; year >= 1900; year--) {
        _years.push(year.toString());
      }
      return _years;
    },
    productNameErrors() {
      const errors = [];
      if (!this.$v.productName.$dirty) return errors;
      !this.$v.productName.required && errors.push("Product Name is required");
      !this.$v.productName.maxLength &&
      errors.push("Product Name must be less than 50 Characters");
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.price.$dirty) return errors;
      !this.$v.price.required && errors.push("Price is required");
      !this.$v.price.minValue && errors.push("The price cannot be negative");
      !this.$v.price.decimal && errors.push('Price must have 2 decimal places or less');
      return errors;
    },
    brandErrors() {
      const errors = [];
      if (!this.$v.brand.$dirty) return errors;
      !this.$v.brand.required &&
      errors.push(
          "At least 1 Vehicle Manufacturer is required, use N/A if Unknown"
      );
      !this.$v.brand.maxLength &&
      errors.push("You cannot add more than 6 Vehicle Manufacturers");
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.model.$dirty) return errors;
      !this.$v.model.required &&
      errors.push("At least 1 Vehicle Model is required, use N/A if Unknown");
      !this.$v.model.maxLength &&
      errors.push("You cannot add more than 6 Vehicle Models");
      return errors;
    },
    manufacturerErrors() {
      const errors = [];
      if (!this.$v.manufacturer.$dirty) return errors;
      !this.$v.manufacturer.required &&
      errors.push("The brand is required, use N/A if Unknown");
      return errors;
    },
    conditionErrors() {
      const errors = [];
      if (!this.$v.condition.$dirty) return errors;
      !this.$v.condition.required && errors.push("The Condition is required");
      return errors;
    },
    yearErrors() {
      const errors = [];
      if (!this.$v.year.$dirty) return errors;
      !this.$v.year.required &&
      errors.push("The Year is required, use N/A if Unknown");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required &&
      errors.push("The Type is required, use N/A if Unknown");
      return errors;
    },
    postcodeErrors() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.maxLength &&
      errors.push("The postcode must be between 1000 and 9999");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.maxLength &&
      errors.push("The Description must be less than 490 Characters");
      return errors;
    },
    tagsErrors() {
      const errors = [];
      if (!this.$v.tags.$dirty) return errors;
      !this.$v.tags.maxLength &&
      errors.push("You cannot add more than 5 Categories");
      return errors;
    },
  },
  validations: {
    productName: {required, maxLength: maxLength(50)},
    price: {required, minValue: minValue(0), decimal: helpers.regex('decimal', /^[-]?\d*(\.\d{1,2})?$/),},
    brand: {required, maxLength: maxLength(6)},
    model: {required, maxLength: maxLength(6)},
    manufacturer: {required},
    condition: {required},
    year: {required},
    type: {required},
    postcode: {maxLength: maxLength(4)},
    description: {maxLength: maxLength(490)},
    tags: {maxLength: maxLength(5)},
  },
  mounted() {
    this.innit();
  },
  methods: {
    ...mapActions("user", ["pwdCheck", "ownerCheck"]),
    ...mapActions("data", ["getData", "postData"]),
    ...mapActions("alert", ["alerts"]),
    ...mapActions("parts", ["editPart"]),
    async innit() {
      await this.pwdCheckCall();
      await this.getPartInfo();
      await this.getImages();
      await this.getBrands();
      await this.getModels();
      await this.getMakers();
      await this.getTags();
      this.usersID = this.currentUser.usersID;
      this.usersPwd = this.currentUser.usersPwd;
    },
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        await this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        await this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        await this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        await this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
    async ownerCheckCall() {
      let response = await this.ownerCheck(this.$route.params.id);
      if (response["error"] === "userNotFound") {
        await this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        await this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        await this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        await this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
    async getPartInfo() {
      let response = await this.postData({
        url: "fetchPart",
        data: {partsID: this.$route.params.id},
      });
      if (response["error"] !== "none") {
        console.log("Error: " + response["error"]);
        let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
        await this.alerts({
          type: e[0],
          msg: e[1],
        });
        return;
      }
      this.productName = response["parts"][0].partsName;
      this.price = response["parts"][0].partsPrice;
      this.brand = this.splitStrArrays(
          response["parts"][0].partsBrand
      );
      this.model = this.splitStrArrays(
          response["parts"][0].partsModel
      );
      this.manufacturer = response["parts"][0].partsMaker;
      this.condition = response["parts"][0].partsCondition;
      this.year = response["parts"][0].partsYear;
      this.type = response["parts"][0].partsType;
      this.address = response["parts"][0].partsAddress;
      this.city = response["parts"][0].partsCity;
      this.postcode = response["parts"][0].partsPostcode;
      this.state = response["parts"][0].partsState;
      this.description = response["parts"][0].partsDesc;
      if (response["parts"][0].partsTags !== null && response["parts"][0].partsTags !== "" && response["parts"][0].partsTags !== undefined) {
        this.tags = this.splitStrArrays(
            response["parts"][0].partsTags
        );
      } else {
        this.tags = [];
      }
      await this.ownerCheckCall();
    },
    async getImages() {
      let response = await this.postData({
        url: "fetchPartImg",
        data: {id: this.$route.params.id},
      });
      if (response["error"] !== "none") {
        console.error("Error: Loading Images " + response["error"]);
      } else {
        let count = 0;
        for (let element of response["images"]) {
          let url = this.baseURL + "/storage/partImgs/" + element.partsImgStr;
          let response = await fetch(url);
          let blob = await response.blob();
          let file = new File([blob], "image_" + count, {type: "image/jpeg"});
          this.images.push(file);
          count++;
        }
      }
    },
    async getBrands() {
      let response = await this.getData({
        url: "getBrands",
      });
      if (response["error"] !== "none") {
        console.log("Error: " + response["error"]);
        let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
        await this.alerts({
          type: e[0],
          msg: e[1],
        });
        return;
      }
      this.brandItems = response["brands"];
      this.brandItems.unshift("N/A");
      this.brandItems.unshift("Any");
    },
    async getModels() {
      let response = await this.getData({
        url: "getModels",
      });
      if (response["error"] !== "none") {
        console.log("Error: " + response["error"]);
        let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
        await this.alerts({
          type: e[0],
          msg: e[1],
        });
        return;
      }
      this.modelItems = response["models"];
      this.modelItems.unshift("N/A");
      this.modelItems.unshift("Any");
    },
    async getMakers() {
      let response = await this.getData({
        url: "getMakers",
      });
      if (response["error"] !== "none") {
        console.log("Error: " + response["error"]);
        let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
        await this.alerts({
          type: e[0],
          msg: e[1],
        });
        return;
      }
      this.manufacturerItems = response["makers"];
      this.manufacturerItems.unshift("N/A");
      this.manufacturerItems.unshift("Manufacturer");
    },
    async getTags() {
      let response = await this.getData({
        url: "getTags",
      });
      if (response["error"] !== "none") {
        console.log("Error: " + response["error"]);
        let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
        await this.alerts({
          type: e[0],
          msg: e[1],
        });
        return;
      }
      this.tagItems = response["tags"];
    },
    // Util Methods
    capitalWord(sentence) {
      const words = sentence.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ");
    },
    splitStrArrays(strArray) {
      return strArray.split(",");
    },
    arrayLengthCheck(array, size) {
      if (array.length > size) {
        array.pop();
      }
    },
    updateImgs(images) {
      this.images = images;
    },
    async editPartCall() {
      this.$v.$touch();
      window.scrollTo(0, 0);
      if (!this.$v.$invalid) {
        if (this.submitLoading) {
          return;
        }
        this.submitLoading = true;
        this.price = parseFloat(this.price);
        if (this.postcode === 0) {
          this.postcode = null;
        }
        this.productName = this.capitalWord(this.productName);
        this.manufacturer = this.capitalWord(this.manufacturer);
        const partInfo = {
          partsID: this.$route.params.id,
          productName: this.productName,
          price: this.price,
          brand: this.brand,
          model: this.model,
          manufacturer: this.manufacturer,
          condition: this.condition,
          year: this.year,
          type: this.type,
          address: this.address,
          city: this.city,
          postcode: this.postcode,
          state: this.state,
          description: this.description,
          tags: this.tags,
          usersID: this.currentUser.usersID,
          usersPwd: this.currentUser.usersPwd,
        };
        let response = await this.editPart({
          data: partInfo,
          images: this.images,
        });
        if (response["error"] !== "none") {
          console.log("Error: " + response["error"]);
          let e = errorCodes[response["error"]] || "An unknown error has occurred, please try again later or login again";
          await this.alerts({
            type: e[0],
            msg: e[1],
          });
          return;
        }
        await this.alerts({
          type: "success",
          msg: "Listing successfully updated!",
        });
        await this.$router.push({
          name: "Part",
          query: {id: response["id"], name: response["name"]},
        });
        this.submitLoading = false;
      }
    },
  },
};
</script>
